@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Six+Caps&display=swap");

$theme-colors: (
    "theme-blue": #3fb4c3,
    "primary": #3fb4c3,
    "dark": #000000,
    "dark-1": #160a3d,
    "secondary": #ffffff,
    "coloured": #00b67a,
    "theme-text": #777777,
    "theme-paragraph": #666666,
);
.pointer {
    cursor: pointer;
}
.bg-main {
    background-color: #f3e7d878 !important;
}
.login-logo {
    max-width: 250px;
    width: 100%;
}
Input:focus {
    box-shadow: none !important;
    outline: none;
}

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

$font-weights: 100 200 300 400 500 600 700 800 900;
// $font-families: "Roboto" "Inter" "Six Caps";
$font-families: "Six Caps";

@each $font-weight in $font-weights {
    .fw--#{$font-weight} {
        font-weight: $font-weight !important;
    }
}
@each $font-family in $font-families {
    .ff--#{str-replace($font-family, " ", "-")} {
        font-family: $font-family, "sans-serif";
    }
}

@for $i from 12 through 96 {
    .fs--#{$i} {
        font-size: $i + px !important;
    }
}

.fs--170 {
    font-size: 170px;
    @media (max-width: 991.98px) {
        font-size: 120px;
    }
}

.pipe {
    width: 1px;
    height: 65px;
    background: white;
}

.card {
    background-color: #ffc10759 !important;
}

@import "node_modules/bootstrap/scss/bootstrap";

// **** Globel Classes **** //

// ._2hTXI {
//     background-color: aliceblue;

//     // img {
//     //     color: #000000;
//     // }
// }
// 25%
.card-image {
    width: 100% !important;
    @media (min-width: 1500px) {
        height: 300px;
    }
}
.w-md-20 {
    @media (min-width: 768px) {
        width: 20% !important;
        height: auto;
    }
}
.width-fit-content {
    width: fit-content;
}
.data-image {
    width: 90%;
    overflow: hidden;
}
.card-image-wrapper {
    height: 280px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    padding: 20px;
    @media (min-width: 1500px) {
        height: auto;
    }

    @media (max-width: 767.98px) {
        height: auto !important;
    }
    &--sm {
        height: 200px;
        @media (max-width: 767.98px) {
            height: 200px !important;
        }
    }
}

// Login page
.login-main {
    max-width: 560px;
    width: 90%;
    margin: auto;
    box-shadow: 0 2px 12px 0 rgba(129, 23, 23, 0.2);
    border-radius: 10px !important;
    &__left {
        &__text {
            font-weight: 500;
            color: #484848;
            font-size: 14px;
        }
    }
    &__right {
        &__headText {
            color: #484848;
            font-size: 23px;
            font-weight: 400;
        }
        &__custom-icon {
            background: white;
            border-radius: 16px;
        }
        &__custom-field {
            height: 40px;
            padding: 0 0 0 10px;
            font-size: 13px;
        }
        &__submit-btn {
            border-radius: 20px;
            height: 40px;
            color: #fff;
        }
        &__btm-text {
            font-size: 11px;
            font-weight: 600;
        }
    }
}

//Navbar Styling

// Header Styling
.detail-bg {
    background: url("../../assets/img/detailbg.png");
}
.header-main {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
    background-blend-mode: overlay;
    min-height: 100vh;
    padding: 0 20px;
    position: relative;
    @media (max-width: 600px) {
        min-height: auto;
    }
    &__logo {
        width: 100%;
        max-width: 150px;
        @media (max-width: 600px) {
            width: 55px !important;
        }
    }
    // &__left {
    // }
    &__bottom-text {
        position: absolute;
        bottom: 0px;
        font-style: normal;
        font-weight: 800;
        font-size: 35px;
        line-height: 150%;
        color: #000000;
        @media (max-width: 600px) {
            font-size: 22px;
            left: 6px;
        }
    }
    &__right {
        &__text-card {
            width: 100% !important;
            word-break: break-word;
            @media (max-width: 600px) {
                width: 100% !important;
                text-align: center !important;
            }
            &--active {
                background: rgba(0, 0, 0, 0.3);
            }
            &__text-heading {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                color: #fff;
            }
            &__text-detail {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 150%;
                color: #fff;
            }
        }
    }
}

//Footer Styling

.footer-main {
    background: #ffc10759;
    // min-height: 300px;
    &__left-text {
        width: 300px;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 170%;
        // color: #e0e0e0;
        @media (max-width: 600px) {
            width: 100% !important;
        }
    }
    &__right-text {
        &__mail-text {
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
            // color: #f2f2f2;
        }
        &__address {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 170%;
            // color: #e0e0e0;
        }
        &__apps {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            // color: #f2f2f2;
            width: 50%;
            @media (max-width: 600px) {
                width: 100% !important;
            }
        }
    }
}

// Product page

.product {
    &-main {
        &__header {
            &__heading {
                font-style: normal;
                font-weight: 700;
                // font-size: 30px;
                // line-height: 58px;
                color: #000000;
                @media (max-width: 767.98px) {
                    font-size: 24px !important;
                    // line-height: 30px;
                }
            }
            &__list-item {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 200%;
            }
        }

        &__card {
            border: 1px solid #000000;
            border-radius: 65px;
            padding: 10px;
            min-height: 280px;

            @media (max-width: 600px) {
                min-height: auto !important;
            }
            &__text {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 200%;
                color: #000000;
            }
        }
        &__btn {
            border-radius: 40px;
        }
    }
    &-title {
        max-width: 500px;
        width: calc(100% - 100px);
        padding: 20px;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 5px;
        bottom: 30px;
        left: 30px;
        font-size: 38px;
        @media (max-width: 767.98px) {
            font-size: 20px;
        }
    }
}
// **** Preliminary Research **** //

.preliminary {
    &__top-image {
        width: 100%;
        height: 60px;
    }
    &__section-title {
        @media (min-width: 760.98px) {
            width: 50%;
        }
        small h2 {
            font-family: "inter";
            font-weight: 400;
        }
    }
}

// **** About Cards **** //
.about {
    @media (min-width: 760.98px) {
        width: 50%;
    }
    h2 small Button {
        font-family: "Inter";
    }
    &__card {
        border: none;
        width: 300px;
        &-title {
            font-family: "Roboto", sans-serif;
        }
        &-text {
            font-family: "Roboto", sans-serif;
        }
    }
    // &__card:active,
    // &__card:hover {
    //     border: 1px solid var(--dark);
    // }
}

.header {
    &-card {
        border: 1px solid transparent;
        border-radius: 25px;
        cursor: pointer;
        &:hover {
            border: 1px solid black;
        }
    }
}
.about {
    &-card {
        border: 1px solid transparent;
        border-radius: 25px;
        cursor: pointer;
        &:hover {
            border: 1px solid black;
        }
    }
}

// **** Product ****//
.color-666666 {
    color: #666666;
}
.carousel {
    padding-bottom: 50px;
}
.carousel .control-dots {
    // top: 100%;
    // bottom: auto;
}
.product {
    @media (min-width: 760.98px) {
        width: 50%;
    }
    &-heading {
        font-family: "Roboto", sans-serif;
    }
    &__carousel {
        border: none !important;
        .carousel .slide .previous {
            opacity: 0.3 !important;
        }
        .carousel .slide .selected {
            opacity: 1 !important;
        }
        // .carousel.carousel-slider {
        //     width: fit-content !important;
        // }
        .carousel.carousel-slider .control-arrow {
            display: none !important;
        }
        .carousel .control-dots .dot.selected {
            opacity: 1;
            width: 20px;
            border-radius: 20px;
            background-color: var(--theme-blue);
            // background-color: #ffffff;
            box-shadow: 1px 1px 2px transparent;
        }
        .carousel .control-dots .dot {
            background-color: #484848;
        }
        // slider animated
        .carousel .slider-wrapper {
            // width: 70%;
            @media (min-width: 991.98px) {
                margin-left: 20%;
            }
        }
        // .slider .animated {
        //     width: 60%;
        // }

        .carousel .slider-wrapper.axis-horizontal .slider {
            @media (min-width: 768px) {
                width: 60%;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
        }
        .carousel .slider-wrapper.axis-horizontal .slider .slide {
            opacity: 0.3;
        }
        .carousel .slider-wrapper.axis-horizontal .slider .slide.selected {
            opacity: 1;
        }
        .myCarousel {
            @media (min-width: 768px) {
                padding-right: 5%;
            }
        }
    }
}

// **** Team ****//
.team {
    @media (min-width: 760.98px) {
        width: 50%;
    }
    &-heading {
        font-family: "Roboto", sans-serif;
    }
    &-title {
        font-family: "Roboto", sans-serif;
    }
    &__carousel {
        .react-multiple-carousel__arrow {
            display: none;
        }
        .react-multi-carousel-dot {
            display: none;
        }
    }
}

// **** Demo ****//
.demo {
    @media (min-width: 760.98px) {
        width: 50%;
    }
    &-heading {
        font-family: "Roboto", sans-serif;
    }
    &-title {
        font-family: "Roboto", sans-serif;
    }
    &__carousel {
        .data-image-gallery {
            @media (min-width: 768px) {
                height: 200px;
                width: 90%;
                overflow: hidden;
            }
            @media (max-width: 767px) {
                width: 100%;
                height: auto;
            }
        }
        // .react-multiple-carousel__arrow {
        //     display: none;
        // }
        // .react-multi-carousel-dot {
        //     display: none;
        // }
        // &__first-slider {
        //     opacity: 0.3;
        // }
        // &__first-slider:active,
        // &__second-slider:hover {
        //     opacity: 1;
        // }
        // &__second-slider {
        //     opacity: 0.3;
        // }
        // &__second-slider:active,
        // &__second-slider:hover {
        //     opacity: 1;
        // }
        // .react-multi-carousel-item {
        //     opacity: 0.3;
        // }
        // .react-multi-carousel-item--active {
        //     opacity: 1;
        // }
    }
}

//
// .GalleryCarousel {
.slick-slide,
.slick-active {
    @media (max-width: 768px) {
        width: 100%;
    }
}
// .slick-slide,
// .slick-cloned {
// width: fit-content !important;
// margin-left: 1px;
// margin-right: 1px;
// }
.slick-arrow,
.slick-prev {
    display: none !important;
}

.slick-arrow,
.slick-next {
    display: none !important;
}
// SwiperCarousel
@media (min-width: 768px) {
    .swiper-slide-active {
        opacity: 1 !important;
    }
    .swiper-slide-next {
        opacity: 0.3 !important;
    }
    .swiper-slide-prev {
        opacity: 0.3 !important;
    }
}
.section-sub-title {
    color: #777777;
    letter-spacing: 0.4em;
}
.section-description {
    color: #666666;
}
.navbar-bg {
    background-color: #0cafa9;
}
.min-h-screen {
    min-height: 100vh;
}
.footer-logo {
    max-width: 150px;
}
