header, footer{
    height: 100px;
    font-size:26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    color: #ffffff;
}

.diagram-container {
  width: 100%;
  height: 60vh;
  background: rgb(245, 245, 245);
}

.custom-node {
  min-width: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.custom-node-header {
  flex-grow: 1;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.custom-node-subheader {
  flex-grow: 1;
  padding: 8px;
  background: #eee;
}
.custom-node-content {
  flex-grow: 1;
  padding: 0 8px 8px 8px;
}
.custom-node-port {
  flex-grow: 1;
  padding: 8px 16px;
  position: relative;
  text-align: center;
}
.custom-node-port-in {
  text-align: left;
}
.custom-node-port-out {
  text-align: left;
}

.circle-port {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #ddd;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.circle-port:hover {
  background: #bbb;
}
.circle-porter {
  position: absolute;
  top: 8px;
}
.circle-porter-in {
  left: -8px;
}
.circle-porter-out {
  right: -8px;
}
.cursor-pointer{
  cursor: pointer;
}
/* ProductDetail Styles */
.product-detail {
    font-family: Arial, sans-serif;
    color: #333;
}

.product-image {
    max-height: 400px;
    object-fit: cover;
}

.product-title {
    bottom: 20px;
    left: 20px;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.product-main {
    background-color: #f8f9fa;
}

.key-features {
    list-style-type: none;
    padding-left: 0;
}

.key-features li {
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
}

.key-features li:last-child {
    border-bottom: none;
}
/* Flashcard Styles */
.flashcard {
    perspective: 1000px;
    height: 300px;
    margin-bottom: 20px;
}

.flashcard .card-body {
    padding: 0;
    height: 100%;
}

.flashcard-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flashcard-inner.is-flipped {
    transform: rotateY(180deg);
}

.flashcard-front, .flashcard-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.flashcard-back {
    transform: rotateY(180deg);
}

.flashcard-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    border: 4px solid white;
}

.flashcard .card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.flashcard .card-text {
    font-size: 0.9rem;
    line-height: 1.4;
    overflow-y: auto;
    max-height: 150px;
    width: 100%;
}

.flashcard .btn {
    width: 100%;
    margin-top: 0.5rem;
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .product-title {
        font-size: 1.5rem;
    }

    .flashcard {
        height: 250px;
    }

    .flashcard-image {
        height: 100px;
    }
}
.footer-main__center-text {
    display: flex;
    flex-direction: column;
}

.footer-main__center-text__mail-text {
    color: #4a90e2;
    font-size: 1.2rem;
    text-decoration: none;
    margin-bottom: 1rem;
}

.footer-main__center-text__address {
    margin-bottom: 1rem;
}

.footer-main__center-text__social {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer-main__center-text__social a {
    color: #4a90e2;
    text-decoration: none;
}

.footer-main__center-social {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    gap: 20px; /* Add space between items */
}

.footer-social-link {
    color: #3fb4c3;
    text-decoration: none;
}

/* Optional: add hover effect */
.footer-social-link:hover {
    text-decoration: underline;
}