.blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.blog-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.blog-intro {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #666;
}

.highlight {
  background-color: #ffc107;
  color: #333;
  padding: 2px 4px;
}

.italic {
  font-style: italic;
}

.section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #800000;
}

.section-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #666;
}

.section-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin: 0 auto;
}

.image-description {
  font-size: 14px;
  color: #999;
}

